<template>
  <tr :class="rowClass">
    <table-cell :label="$t('cfecode')">
      <div class="d-flex align-center">
        <v-btn
          v-if="canAdd"
          :disabled="!validRow"
          class="mr-4"
          color="success"
          icon
          outlined
          x-small
          @click="onAddItem"
        >
          <icon-add />
        </v-btn>

        <v-btn
          v-else-if="canDelete && !signed"
          class="mr-4"
          color="error"
          icon
          outlined
          x-small
          @click="onDeleteItem"
        >
          <icon-remove />
        </v-btn>
        <cfe-code-select
          :disabled="signed || edit"
          :value="cfeCodeId"
          @change="onSelectCfeCode"
        />
      </div>
    </table-cell>

    <table-cell :label="$t('tax.percent')">
      <form-field-text
        :disabled="signed || edit"
        :value="taxPercent"
        input-type="number"
        label="tax.percent"
        required
        rules="required|min_value:0.001|max_value:999.999"
        single-line
        @save="onAddItem"
        @input:debounce="onSetTaxPercent"
      />
    </table-cell>

    <table-cell :label="$t('fee')">
      <form-field-text
        :disabled="signed"
        :value="totalPrice"
        input-type="number"
        label="fee"
        required
        rules="required"
        single-line
        @save="onAddItem"
        @input:debounce="onSetTotalPrice"
      />
    </table-cell>

    <table-cell :label="$t('value')">
      <simple-unit-price-field
        :disabled="signed || edit"
        :value="unitPrice"
        update-on-blur
        @price:apply="onAddItem"
        @price:update="onSetUnitPrice"
      />
    </table-cell>

    <table-cell :label="$t('actions')" class="text-lg-right">
      <item-actions
        v-if="(canAdd || canDelete) && !edit"
        :can-add="canAdd"
        :can-delete="canDelete"
        :disabled="!validRow || signed"
        hide-reset
        @item:add="onAddItem"
        @item:delete="onDeleteItem"
      />
    </table-cell>
  </tr>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { CfeCode, InvoicePosition } from "@planetadeleste/vue-mc-gw";

import CfeCodeSelect from "@/modules/cfecodes/components/CfeCodesSelect.vue";
import TableCell from "@/components/common/TableCell.vue";
import SimpleUnitPriceField from "@/modules/invoices/components/SimpleUnitPriceField.vue";
import ItemActions from "@/modules/invoices/components/rows/ItemActions.vue";
import { InvoiceModule } from "@/store/invoice";
import {
  getPercent,
  getTotalOfAppliedPercent,
  percent,
} from "@/plugins/helpers";
import IconAdd from "@/components/icons/IconAdd.vue";
import IconRemove from "@/components/icons/IconRemove.vue";
import { number } from "mathjs";

@Component({
  components: {
    IconRemove,
    IconAdd,
    CfeCodeSelect,
    TableCell,
    SimpleUnitPriceField,
    ItemActions,
  },
})
export default class ReceiptItemRow extends Vue {
  @VModel({ type: Object, default: () => new InvoicePosition() })
  item!: InvoicePosition;

  @Prop(Boolean) readonly edit!: boolean;

  obItem: InvoicePosition | null = null;
  totalPrice = 0;

  /**
   * @var {string} holdField Used to prevent auto overide value
   */
  holdField: "unit" | "tax" | null = null;

  @Prop(Boolean) readonly canAdd!: boolean;
  @Prop(Boolean) readonly canDelete!: boolean;

  get unitPrice(): number {
    return number(this.item.get("unit_price", 0));
  }

  set unitPrice(fValue: number) {
    if (this.holdField === "unit") {
      return;
    }

    this.onSetUnitPrice(fValue);
  }

  get taxPercent(): number {
    return number(this.item.get("tax_percent", 0));
  }

  get cfeCodeId(): number {
    return this.item.get("item_id", 0);
  }

  set cfeCodeId(sValue: number) {
    this.item.set("item_id", sValue);
  }

  get invoice() {
    return InvoiceModule.invoice;
  }

  get signed() {
    return InvoiceModule.signed || InvoiceModule.isSigning;
  }

  get rowClass() {
    return {
      "invoice-table-row": true,
      "invoice-table-row--edit": this.canAdd,
      "v-data-table__mobile-table-row": this.isMobile,
    };
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  get validRow() {
    return true;
  }

  get changed(): boolean {
    if (!this.obItem) {
      return true;
    }

    return (
      number(this.obItem.get("tax_percent", 0)) !== this.taxPercent ||
      number(this.obItem.get("unit_price", 0)) !== this.unitPrice
    );
  }

  get idx(): string | number {
    return this.obItem ? this.obItem.get("idx", 0) : this.item.get("idx", 0);
  }

  mounted() {
    this.onCalculateTotalPrice();
  }

  setLocalPosition() {
    this.obItem = new InvoicePosition(this.item.attributes);
  }

  // get totalPrice(): number {
  //   if (!this.unitPrice || !this.taxPercent) {
  //     return 0;
  //   }

  //   return percent(this.unitPrice, this.taxPercent);
  // }

  onAddItem() {
    if (!this.validRow || !this.canAdd) {
      return;
    }

    this.$emit("add:empty");
  }

  onDeleteItem() {
    if (!this.canDelete) {
      return;
    }

    this.$emit("del", this.item);
  }

  onUpdateItem() {
    if (
      this.signed ||
      !this.changed ||
      !this.unitPrice ||
      !this.taxPercent ||
      !this.totalPrice
    ) {
      return;
    }

    this.setLocalPosition();
    this.$emit("update", this.item);
  }

  onSetUnitPrice(fValue: number) {
    fValue = number(fValue);

    if (this.holdField === "unit" || this.unitPrice === fValue) {
      return;
    }

    this.holdField = "unit";
    this.item.set("unit_price", fValue);
    this.item.set("price", fValue);
    this.item.set("price_with_discounts", fValue);

    if (this.totalPrice) {
      this.onSetTotalPrice(this.totalPrice);
    } else {
      this.onCalculateTotalPrice();
    }
  }

  onSetTaxPercent(fValue: number) {
    fValue = number(fValue);

    if (this.holdField === "tax" || fValue === this.taxPercent) {
      return;
    }

    this.holdField = "tax";
    this.item.set("tax_percent", fValue);

    if (this.totalPrice) {
      this.onSetTotalPrice(this.totalPrice);
    } else {
      this.onCalculateTotalPrice();
    }
  }

  onSetTotalPrice(fValue: number) {
    fValue = Number(fValue);

    if (!fValue) {
      return;
    }

    this.totalPrice = fValue;

    // Calculate tax percent
    if (this.unitPrice && !this.taxPercent && !this.edit) {
      const fPercent = getPercent(this.unitPrice, fValue, true, 3);
      this.onSetTaxPercent(fPercent);
    }

    // Calculate unit price
    if (this.taxPercent) {
      const fPriceValue = percent(fValue, this.taxPercent, true);
      this.onSetUnitPrice(fPriceValue);
    }

    this.$_.delay(this.onCalculateTotalPrice, 500);
  }

  onCalculateTotalPrice() {
    if (this.unitPrice && this.taxPercent) {
      const fValue = getTotalOfAppliedPercent(
        this.unitPrice,
        this.taxPercent,
        true
      );

      if (fValue !== this.totalPrice) {
        this.totalPrice = fValue;
        // return;
      }
    }

    this.holdField = null;
    this.onUpdateItem();
  }

  onSelectCfeCode(obModel: CfeCode) {
    this.item.set("item_id", obModel.id);
    this.item.set("cfe_code", obModel.attributes);
  }
}
</script>
